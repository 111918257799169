<template>
  <hero-navigation>
    <div class="projects-nav-elements">
      <router-link class="button-outline-blue" to="/time-log/weekly">
        Weekly Time Log
      </router-link>
      <router-link class="button-outline-blue" to="/time-log/team">
        Team Time Log Overview
      </router-link>
    </div>
  </hero-navigation>
</template>
<script>
import HeroNavigation from '../components/HeroNavigation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'time-log-navigation',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['me', 'isAdmin', 'isManager', 'isManagerAndBigger'])
  },
  components: {
    HeroNavigation
  }
}
</script>
